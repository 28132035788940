<template>
  <div class='join'>
    <div class="banner"></div>
    <h3>开启新的工作</h3>
    <div class="join-content">
        <dl>
            <dt>岗位</dt>
            <dd v-for="(item,idx) in jobs "  :key="item.id" :class="active == idx ? 'active' : '' " @click="active = idx " >{{item.name}}</dd>
        </dl>
        <JobDetail :active="active" :job="jobs[active]" ></JobDetail>
    </div>
  </div>
</template>

<script>
import JobDetail from './jobDetail.vue'
export default {
  components: {JobDetail},
  data() {
    return {
        active: 0,
        jobs:[
            {   
                id: 1,
                name: 'C++',
                city: '成都',
                job: '研发-C++',
                duty:[
                    '负责云安全产品的架构设计，开发；',
                    '负责核心模块的性能测试与调优;',
                    '负责开发和完善后台相关工具、系统和流程建设。'
                ],
                skill:[
                    '熟悉 Openresty 工作方式， 熟悉 nginx 代码框架，熟悉c/c++，lua， python，go等一种或多种语言，熟悉mongo，redis等数据库。',
                    '在系统架构设计、性能优化、可靠性和扩展性等一方面有所专长。'
                ],
                extr:[
                    '技术专利;',
                    '个人博客;',
                    '作品展示;'
                ]
            },
            {   
                id: 2,
                name: '渗透测试',
                city: '成都',
                job: '安全-渗透测试',
                duty:[
                    '承担高级攻防类项目（红队攻防，内网渗透，定制化渗透，持续性渗透等）；',
                    '跟踪国内外安全动态，对安全事件中的漏洞进行分析、研究、挖掘，并进行预警提供应急响应和调查取证服务；',
                    '参与内部标准化工作、自动化工具设计；',
                    '安全领域新技术的研究，探索，设计，落地。'
                ],
                skill:[
                    '能够熟练的对业务系统进行黑盒、白盒测试；',
                    '熟悉常见攻防技术原理、网络流量分析，有攻防实战经验更佳；',
                    '熟练掌握各种渗透测试方法和流程。'
                ],
                extr:[
                    '技术专利;',
                    '个人博客;',
                    '作品展示;'
                ]
            },
            {   
                id: 3,
                name: '后台开发工程师',
                city: '成都',
                job: '研发-后台开发',
                duty:[
                    '负责云安全产品的架构设计，开发；',
                    '负责核心模块的性能测试与调优；',
                    '负责开发和完善后台相关工具、系统和流程建设。'
                ],
                skill:[
                    '计算机相关专业本科及以上学历，5年以上软件研发经验；',
                    '熟悉 Openresty 工作方式， 熟悉 nginx 代码框架，熟悉c/c++，lua， python，go等一种或多种语言，熟悉mongo，redis等数据库；',
                    '在系统架构设计、性能优化、可靠性和扩展性等一方面有所专长。'
                ],
                extr:[
                    '技术专利;',
                    '个人博客;',
                    '作品展示;'
                ]
            },
            {   
                id: 4,
                name: '前端开发工程师',
                city: '成都',
                job: '研发-前端开发',
                duty:[
                    '负责前端框架搭建，制定前端开发规范，核心代码开发；',
                    '能与产品经理、UI设计及后端人员及时高效沟通，保证产品质量与产品开发进度；',
                    '持续优化前端页面性能和页面交互体验，实现高性能，高可用及安全的前端技术框架。'
                ],
                skill:[
                    '熟练使用HTML5/CSS3/JavaScript(ES6+)，研读过HTML解析引擎设计与实现，JS解析引擎设计与实现；',
                    '掌握HTTP协议相关知识，熟悉Nginx，至少了解并使用过一种后端语言，掌握至少一种数据库优先；',
                    '熟悉主流前端框架(VUE/REACT等)的区别与设计思想，阅读过至少一种框架的源码，了解其核心实现机制；',
                    '对前端工程化有一定的实践经验(开发流程/构建优化/打包部署/自动化/工具化/模块化等)；',
                    '热爱前端技术，注重代码质量，主动通过算法及数据结构知识优化项目代码提高生产效率。'
                ],
                extr:[
                    '技术专利;',
                    '个人博客;',
                    '作品展示;'
                ]
            }
            
        ]
    };
  },
  computed: {},
  watch: {},
  methods: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang='scss' scoped>
.join{
    .banner{
        height: 390px;
        background-image: url('../../assets/images/home/img_zhaopin.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    >h3{
        height: 42px;
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 42px;
        margin-top: 50px;
        margin-bottom: 56px;
        text-align: center;
    }
    &-content{
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 100px;
        display: flex;
        
        dl{
            margin-right: 40px;
        }
        dt{
            width: 120px;
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 22px;
        }
        dd{
            // width: 56px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            margin-top: 30px;
            cursor: pointer;
        }
        dd:hover{
            color: #2149DD;
        }
        .active{
            color: #2149DD;
        }
    }

}
</style>