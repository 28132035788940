<template>
    <div class='detail'>
        <div class="box">
            <h2>{{job.name}}工程师</h2>
            <div class="where">
                <span>成都</span>
                <el-divider direction="vertical"></el-divider>
                <span>{{job.job}}</span>
            </div>
            <div class="cla1 common ">
                <h3>职位描述</h3>
                <p>岗位职责</p>
                <p v-for="(item,idx) in job.duty" :key="item">{{idx+1+'. '+item}}</p>
            </div>
            <div class="cla1 common ">
                <h3>职位要求</h3>
                <p v-for="(item,idx) in job.skill" :key="item">{{idx+1+'. '+item}}</p>
            </div>
            <div class="more common ">
                <p>加分项：</p>
                <p v-for="(item,idx) in job.extr" :key="item">{{idx+1+'. '+item}}</p>
            </div>
            <div class="email">
                <i class="divider"></i>
                <div class="box1">
                    <img src="../../assets/images/about/icon_email.png" alt="">
                    <dl>
                        <dt>投递邮箱：</dt>
                        <dd>
                            dongzxa@dzxinanse.com
                            <img src="../../assets/images/about/icon_copy.png" alt=""
                                v-clipboard:copy="'tangl@dzxinansec.com'"
                                v-clipboard:success="onCopy"
                            >
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        job:{
            type: Object,
            default: ()=> {
                return {}

            }
        }
    },
  components: {},
  data() {
    return {
        
    };
  },
  methods:{
    onCopy(v){
        this.$message({
            message: '复制成功',
            type: 'success'
        });
    }
  }
};
</script>

<style lang='scss' scoped>
.detail{
    border-left: 1px solid #E9E9E9;
    padding-left: 70px;
    .box{
        box-sizing: border-box;
        h2{
            // width: 210px;
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 42px;
        }
        .where{
            margin-bottom: 40px;
            span{
                display: inline-block;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 26px;
                margin-top: 20px;
            }
            span:first-child{
                margin-right: 10px;
            }
            span:last-child{
                margin-left: 10px;
            }
        }
        h3{
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 28px;
            margin-bottom: 10px;
        }
        .cla1{
            margin-bottom: 50px;
            max-width: 870px;
        }
        .common{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 30px;
        }
        .email{
            margin-top: 50px;
            i{
                display: inline-block;
                width: 100%;
                height: 1px;
                background: #F1F1F1;
            }
            .box1{
                display: flex;
                align-items: center;
                margin-top: 30px;
                img{
                    width: 60px;
                    height: 60px;
                }
                dl{
                    margin-left: 20px;
                    dt{
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 20px;
                    }
                    dd{
                        font-size: 20px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 28px;
                        img{
                            width: 16px;
                            height: 16px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        
    }
}
</style>